/* istanbul ignore file */

import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';

export function useColumnCountResponsive() {
  const { isPhone, isPhoneLandscape, isTablet, isDesktop, isDesktopLarge, isExtraWide } =
    useBreakpoints();

  let columnSize = 3;
  if (isExtraWide) {
    columnSize = 3;
  } else if (isDesktopLarge) {
    columnSize = 3;
  } else if (isDesktop) {
    columnSize = 3;
  } else if (isTablet) {
    columnSize = 2;
  } else if (isPhone || isPhoneLandscape) {
    columnSize = 1;
  } else {
    // do nothing
  }

  return columnSize;
}

export function useColumnCountResponsiveSmallContent() {
  const { isPhone, isPhoneLandscape, isTablet, isDesktop, isDesktopLarge, isExtraWide } =
    useBreakpoints();

  let columnSize = 2;
  if (isExtraWide) {
    columnSize = 6;
  } else if (isDesktopLarge) {
    columnSize = 5;
  } else if (isDesktop) {
    columnSize = 4;
  } else if (isTablet) {
    columnSize = 2;
  } else if (isPhoneLandscape) {
    columnSize = 1;
  } else if (isPhone) {
    columnSize = 1;
  } else {
    // do nothing
  }

  return columnSize;
}
