/* istanbul ignore file */

import { defineMessages, useIntl } from 'react-intl';
import { ProductCart } from '@slicekit/core';
import Link from 'next/link';
import { TextBody } from '@cbhq/cds-web/typography';

import { DynamicColumns } from ':dapp/components/DynamicColumns/DynamicColumns';
import { OCSSectionHeader } from ':dapp/pages/ocs/components/OCSSectionHeader';

import { externalRewards } from '../allowedProducts';

import { ShopSection } from './BuyNowSection';
import { ExternalPrizeCard } from './ExternalPrizeCard';
import { PrizeCard } from './PrizeCard';

const messages = defineMessages({
  ocsRedeemPrizes: {
    defaultMessage: 'Redeem prizes',
    description: 'Redeem prizes',
  },
  ocsRedeemPrizesSectionHeaderLink: {
    defaultMessage: 'How does it work?',
    description: 'link for the redeem prizes section header for onchain summer',
  },
});

export type ProductCardProps = {
  product: ProductCart;
};

export function RedeemPrizesSection({ products }: ShopSection) {
  const { formatMessage } = useIntl();

  return (
    <>
      <OCSSectionHeader
        title={formatMessage(messages.ocsRedeemPrizes)}
        subtitle="Complete experiences and redeem points for prizes"
        spacingTop={4}
        rightContent={
          <Link href="/ocs/faq">
            <TextBody as="span" color="primary">
              {formatMessage(messages.ocsRedeemPrizesSectionHeaderLink)}
            </TextBody>
          </Link>
        }
        id="redeem-prizes"
      />
      <DynamicColumns>
        {externalRewards.map((externalReward) => (
          <ExternalPrizeCard
            externalReward={externalReward}
            key={`ocs-play-tab-cards-${externalReward.level}`}
          />
        ))}
        {products?.map((product) => (
          <PrizeCard product={product} key={`ocs-play-tab-cards-${product.dbId}`} />
        ))}
      </DynamicColumns>
    </>
  );
}
