/* istanbul ignore file */

import Image from 'next/image';
import Link from 'next/link';
import { Box } from '@cbhq/cds-web/layout';

import { InfiniteScrollingCarousel } from ':dapp/components/InfiniteScrollingCarousel/InfiniteScrollingCarousel';
import { OCSSectionHeader } from ':dapp/pages/ocs/components/OCSSectionHeader';

export function CryptoFriendlyMerchantsSection() {
  return (
    <div>
      <OCSSectionHeader
        title="Crypto-friendly merchants"
        subtitle="Shop from merchants that accept crypto"
        spacingTop={4}
      />
      <InfiniteScrollingCarousel compact>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://www.aloyoga.com/" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/1.Alo.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://warpcast.com/~/warps" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/2.Warpcast.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://www.standwithcrypto.org/donate" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/3.Stand%20with%20crypto.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://thehundreds.com/" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/4.The%20Hundreds.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://www.bankless.com/join" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/5.Bankless%20%281%29.png"
              alt="warpcast"
              height={150}
              width={150}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://coinbaseshop.com/" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/6.Coinbase%20Shop.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://infiniteobjects.com/" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/7.Infinite%20Objects.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://devourgo.io/" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/8.DevourGO.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://www.coingecko.com/" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/9.Coingecko.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://dexscreener.com/" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/10.Dexscreener.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
        <Box justifyContent="center" alignItems="center" width={200} height={200}>
          <Link href="https://porkbun.com/" target="_blank">
            <Image
              src="https://go.wallet.coinbase.com/static/12.Porkbun.png"
              alt="warpcast"
              height={200}
              width={200}
              quality={100}
            />
          </Link>
        </Box>
      </InfiniteScrollingCarousel>
    </div>
  );
}
