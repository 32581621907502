/* istanbul ignore file */

import { ReactNode } from 'react';
import { Grid } from '@cbhq/cds-web/layout';

import {
  useColumnCountResponsive,
  useColumnCountResponsiveSmallContent,
} from ':dapp/components/DynamicColumns/ColumnCountHook';

export type DynamicColumnsProps = {
  children: ReactNode[] | ReactNode;
  maxColumnCount?: number;
};
export function DynamicColumns(props: DynamicColumnsProps) {
  const columnSize = useColumnCountResponsive();
  const adjustedColumnCount = Math.min(columnSize, props.maxColumnCount ?? 3);

  return (
    <Grid templateColumns={`repeat(${adjustedColumnCount}, minmax(0, 1fr))`} gap={4} width="100%">
      {props.children}
    </Grid>
  );
}
export function DynamicColumnsSmallContent(props: DynamicColumnsProps) {
  const columnSize = useColumnCountResponsiveSmallContent();

  return (
    <Grid templateColumns={`repeat(${columnSize}, minmax(0, 1fr))`} gap={4} width="100%">
      {props.children}
    </Grid>
  );
}
