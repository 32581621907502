/* istanbul ignore file */

import { RefObject, useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SlicerBasics } from '@slicekit/core';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Button } from 'wallet-cds-web/components/Button';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';
type ShopCardProps = {
  shop: SlicerBasics;
  reference?: RefObject<HTMLDivElement>;
};
const coverFit = {
  objectFit: 'cover' as const
};
const messages = defineMessages({
  visitStore: {
    defaultMessage: 'Visit store',
    description: 'Visit store'
  }
});
export function ShopCard({
  shop,
  reference
}: ShopCardProps) {
  const image = useMemo(() => ({
    src: shop.image!
  }), [shop]);
  const {
    formatMessage
  } = useIntl();
  const router = useRouter();
  const action = useMemo(() => ({
    text: formatMessage(messages.visitStore),
    url: `/ocs/shop/${shop.id}`,
    type: 'link' as const
  }), [shop, formatMessage]);
  const performNavigation = useCallback(() => {
    router.push(action.url);
  }, [action.url, router]);
  return <VStack justifyContent="space-between" className={"v3fuisf"} borderRadius="roundedLarge" spacing={2} width="100%" maxWidth="437px" onClick={performNavigation} ref={reference}>
      <VStack>
        <div className={"dao1mf2"}>
          <Image src={image.src} fill style={coverFit} alt="" />
        </div>
        <Box height="16px" />

        <TextTitle3 as="p">{shop.name}</TextTitle3>
        <TextBody as="p" color="foregroundMuted" numberOfLines={3}>
          {shop.description}
        </TextBody>
        <Box height="16px" />
      </VStack>
      <ThemeProvider spectrum="light">
        <Button testID="ocs-card-cta" variant="secondary" block compact numberOfLines={1} to={action.url}>
          {action.text}
        </Button>
      </ThemeProvider>
    </VStack>;
}

require("./ShopCard.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ShopCard.tsx");