/* istanbul ignore file */

import { useMemo } from 'react';
import { useGetUserState } from 'cb-wallet-data/hooks/Gamification/useUserOCSProfile';

import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { SingleProductGenericCard } from ':dapp/pages/ocs/products/components/SingleProductGenericCard';
import { useRewardRedeemed } from ':dapp/pages/ocs/utils/useRewardRedeemed';

import { allRewards } from '../allowedProducts';
import { defaultProductImage } from '../index.page';

import { ProductCardProps } from './ProductCard';

export function PrizeCard({ product }: ProductCardProps) {
  const image = useMemo(() => ({ src: product.images?.[0] || defaultProductImage }), [product]);
  const level = allRewards.find(
    (reward) => reward.productId === product.productId && reward.slicerId === product.slicerId,
  )?.level;

  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });
  const { userState } = useGetUserState(account?.primaryAddress as string);
  const userLevel = Number(userState?.levelData?.currentLevel?.level ?? 0);

  const redeemed = useRewardRedeemed({
    unlock: level,
    slicerId: product.slicerId,
    productId: product.productId,
    buyer: account?.primaryAddress as `0x${string}`,
    userLevel,
  });

  return (
    <SingleProductGenericCard
      image={image.src}
      name={product.name}
      companyLogo={product.slicerImage!}
      companyName={product.slicerName!}
      link={`/ocs/shop/${product.slicerId}/product/${product.productId}`}
      userLevel={userLevel}
      unlock={level}
      redeemed={redeemed}
    />
  );
}
