/* istanbul ignore file */

import { useMemo } from 'react';
import { getDisplayedPrices, ProductCart } from '@slicekit/core';

import { SingleProductGenericCard } from ':dapp/pages/ocs/products/components/SingleProductGenericCard';

import { ethUsd } from '../allowedProducts';
import { defaultProductImage } from '../index.page';

export type ProductCardProps = {
  product: ProductCart;
};

export function ProductCard({ product }: ProductCardProps) {
  const image = useMemo(() => ({ src: product.images?.[0] || defaultProductImage }), [product]);

  const { displayedFullPrice } = getDisplayedPrices({ product, ethUsd });
  return (
    <SingleProductGenericCard
      image={image.src}
      name={product.name}
      price={displayedFullPrice}
      companyLogo={product.slicerImage!}
      companyName={product.slicerName!}
      link={`/ocs/shop/${product.slicerId}/product/${product.productId}`}
    />
  );
}
