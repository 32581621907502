/* istanbul ignore file */

import { ReactNode, RefObject, useEffect, useRef } from 'react';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { useColumnCountResponsive } from '../DynamicColumns/ColumnCountHook';
import { useCarousel } from './CarouselProvider';
export const testID = 'carousel-container';

/**
 * Maybe in the future this testing fixture can
 * be upgraded to be how the component primarily functions,
 * however right now the deadline is tight so i've just
 * added this fixture so that we can test it properly
 * without the ref prop getting in the way.
 */
type CarouselContainerTestingFixtureProps = {
  children: ReactNode[];
  childTestIDs: string[];
};
export function CarouselContainerTestingFixture({
  children,
  childTestIDs
}: CarouselContainerTestingFixtureProps) {
  const ref = useRef<HTMLDivElement>(null);
  const renderedChildren = [];
  for (let i = 0; i < children.length; i++) {
    if (i === 0) {
      renderedChildren.push(<div ref={ref} key={childTestIDs[i]}>
          {children[i]}
        </div>);
    } else {
      renderedChildren.push(<div key={childTestIDs[i]}>{children[i]}</div>);
    }
  }
  return <CarouselContainer watchedHeightRef={ref}>{renderedChildren}</CarouselContainer>;
}
type CarouselContainerProps = {
  children: ReactNode;
  watchedHeightRef: RefObject<HTMLDivElement>;
};
export function CarouselContainer({
  children,
  watchedHeightRef
}: CarouselContainerProps) {
  const carousel = useCarousel();
  const columns = useColumnCountResponsive();
  let gridStyle;
  switch (columns) {
    case 3:
      {
        gridStyle = "c1pwh2m1";
        break;
      }
    case 2:
      {
        gridStyle = "cys5ca9";
        break;
      }
    case 1:
      {
        gridStyle = "c17xvu06";
        break;
      }
    default:
      {
        throw new Error('unexpected column count');
      }
  }
  const spacerRef = useRef<HTMLDivElement>(null);
  const performResize = () => {
    if (spacerRef.current && watchedHeightRef.current) {
      spacerRef.current.style.height = `${watchedHeightRef.current.clientHeight}px`;
    }
  };
  const setUpHeightObserver = () => {
    if (watchedHeightRef.current) {
      new ResizeObserver(performResize).observe(watchedHeightRef.current);
    }
    performResize();
  };
  useEffect(setUpHeightObserver);
  return <div className={"d9i7bp3"} ref={spacerRef}>
      {/* required or the carousel causes width growth */}
      <Box position="absolute" width="100%" ref={watchedHeightRef} key="floating-container-box">
        <HStack testID={testID} ref={carousel.carouselRef} key="carousel-parent" className={gridStyle}>
          {children}
        </HStack>
      </Box>
    </div>;
}

require("./CarouselContainer.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./CarouselContainer.tsx");