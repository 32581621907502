/* istanbul ignore file */

import { defineMessages, useIntl } from 'react-intl';
import { useOrders } from '@slicekit/react';
import { Button } from 'wallet-cds-web/components/Button';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextTitle1 } from '@cbhq/cds-web/typography';
import { THE_GRAPH_KEY } from ':dapp/config/env';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { allSlicerIds } from ':dapp/pages/ocs/shop/allowedProducts';
import { OrderHistorydWalletSVG } from ':dapp/pages/ocs/shop/components/OrderHistorydWalletSVG';
const messages = defineMessages({
  ocsOrderHistoryWelcome: {
    defaultMessage: 'Welcome back!',
    description: 'Welcome back to the shop'
  },
  ocsOrderHistoryDescription: {
    defaultMessage: 'Looking for past orders? Track your purchases in one place',
    description: 'Past orders'
  },
  ocsOrderHistoryButton: {
    defaultMessage: 'View order history',
    description: 'View order history'
  }
});
export function WelcomeBackBanner() {
  const {
    isPhone,
    isPhoneLandscape,
    isTablet
  } = useBreakpoints();
  const {
    formatMessage
  } = useIntl();
  const {
    account
  } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS
  });
  const {
    data
  } = useOrders({
    buyer: account?.primaryAddress as `0x${string}`,
    slicerIds: allSlicerIds,
    thegraphApiKey: THE_GRAPH_KEY
  });
  if (!data?.orders?.length) return null;
  if (isPhone || isPhoneLandscape || isTablet) {
    return <VStack justifyContent="space-between" className={"v1mejcgh"} borderRadius="roundedLarge" position="relative" gap={1} spacing={4} width="100%">
        <ThemeProvider spectrum="light">
          <TextTitle1 as="p">{formatMessage(messages.ocsOrderHistoryWelcome)}</TextTitle1>
          <TextBody as="p">{formatMessage(messages.ocsOrderHistoryDescription)}</TextBody>
        </ThemeProvider>
        <Box height="8px" />
        <Box>
          <Button variant="secondary" href="/ocs/orders" compact>
            {formatMessage(messages.ocsOrderHistoryButton)}
          </Button>
        </Box>
        <Box height="149px" />
        <div className={"d1si6qpc"}>
          <OrderHistorydWalletSVG size="smol" />
        </div>
      </VStack>;
  }
  return <VStack justifyContent="space-between" className={"vqrhquk"} borderRadius="roundedLarge" position="relative" gap={1} spacing={4} width="100%">
      <ThemeProvider spectrum="light">
        <TextTitle1 as="p">{formatMessage(messages.ocsOrderHistoryWelcome)}</TextTitle1>
        <TextBody as="p">{formatMessage(messages.ocsOrderHistoryDescription)}</TextBody>
      </ThemeProvider>
      <Box height="8px" />
      <Box>
        <Button variant="secondary" href="/ocs/orders" compact>
          {formatMessage(messages.ocsOrderHistoryButton)}
        </Button>
      </Box>
      <Box position="absolute" bottom="0px" right="57px">
        <OrderHistorydWalletSVG size="big" />
      </Box>
    </VStack>;
}

require("./WelcomeBackBanner.linaria.module.css!=!../../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./WelcomeBackBanner.tsx");