/* istanbul ignore file */

import { useEffect, useState } from 'react';
import { validatePurchaseUnits } from '@slicekit/core';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';

type RewardRedeemedProps = {
  unlock: number | string | undefined;
  slicerId: number;
  productId: number;
  buyer: `0x${string}`;
  userLevel: number;
};

export function useRewardRedeemed({
  unlock,
  slicerId,
  productId,
  buyer,
  userLevel,
}: RewardRedeemedProps) {
  const [redeemed, setRedeemed] = useState(false);

  // eslint-disable-next-line wallet/require-named-effect
  useEffect(() => {
    const handleRedeemed = async () => {
      const purchases = await validatePurchaseUnits(getConfig(), {
        slicerId,
        productId,
        buyer,
      });

      if (Number(purchases)) {
        setRedeemed(true);
      }
    };

    if (unlock && userLevel >= Number(unlock)) {
      handleRedeemed();
    }
  }, [unlock, slicerId, productId, buyer, userLevel]);

  return redeemed;
}
