import { useRef } from 'react'; /* istanbul ignore file */
import { defineMessages, useIntl } from 'react-intl';
import { SlicerBasics } from '@slicekit/core';

import { CarouselArrows } from ':dapp/components/ResponsiveCarousel/CarouselArrows';
import { CarouselContainer } from ':dapp/components/ResponsiveCarousel/CarouselContainer';
import { CarouselProvider } from ':dapp/components/ResponsiveCarousel/CarouselProvider';
import { OCSSectionHeader } from ':dapp/pages/ocs/components/OCSSectionHeader';

import { ShopCard } from './ShopCard';

type ShopsProps = {
  shops?: SlicerBasics[];
};

const messages = defineMessages({
  shopsYouLove: {
    defaultMessage: "Shops you'll love",
    description: "Shops you'll love",
  },
  ocsShopsSubtitle: {
    defaultMessage: 'Featured merch from the community',
    description: 'Subtitle for the shops section',
  },
});

export function ShopsYouWillLoveSection({ shops }: ShopsProps) {
  const { formatMessage } = useIntl();
  const innerHeightTrackerRef = useRef<HTMLDivElement>(null);
  if (shops?.length === 0) {
    return null;
  }

  return (
    <CarouselProvider>
      <OCSSectionHeader
        title={formatMessage(messages.shopsYouLove)}
        subtitle={formatMessage(messages.ocsShopsSubtitle)}
        spacingTop={4}
        rightContent={<CarouselArrows />}
      />
      <CarouselContainer watchedHeightRef={innerHeightTrackerRef}>
        {shops?.map((shop) => (
          <ShopCard
            shop={shop}
            key={`ocs-shops-tab-love-cards-${shop.id}-${innerHeightTrackerRef}`}
            reference={innerHeightTrackerRef}
          />
        )) ?? []}
      </CarouselContainer>
    </CarouselProvider>
  );
}
