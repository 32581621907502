/* istanbul ignore file */

import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ProductCart } from '@slicekit/core';
import Link from 'next/link';
import { PartialPaletteConfig, useSpectrum } from '@cbhq/cds-common';
import { Icon } from '@cbhq/cds-web/icons';
import { Box } from '@cbhq/cds-web/layout';
import { Pressable, ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';
import { DynamicColumns } from ':dapp/components/DynamicColumns/DynamicColumns';
import { OCSSectionHeader } from ':dapp/pages/ocs/components/OCSSectionHeader';
import { RouterPathname } from ':dapp/utils/RoutesEnum';
import { ProductCard } from './ProductCard';
const messages = defineMessages({
  ocsBuyNow: {
    defaultMessage: 'Buy now',
    description: 'Buy now'
  },
  ocsBuyNowSeeAllLink: {
    defaultMessage: 'See all',
    description: 'link to see all assorted items you can buy for onchain summer'
  },
  ocsShopAll: {
    defaultMessage: 'Shop all',
    description: 'link to see all assorted items you can buy for onchain summer'
  }
});
const buttonClass = "b1x4xk9x";
export type ShopSection = {
  products?: ProductCart[];
};
export function BuyNowSection({
  products
}: ShopSection) {
  const {
    formatMessage
  } = useIntl();
  const spectrum = useSpectrum();
  const paletteOverride: PartialPaletteConfig = useMemo(() => ({
    secondary: spectrum === 'dark' ? 'gray100' : 'gray0',
    secondaryForeground: spectrum === 'dark' ? 'gray0' : 'gray100'
  }), [spectrum]);
  return <>
      <OCSSectionHeader title={formatMessage(messages.ocsBuyNow)} subtitle="Buy Onchain Summer exclusives, from creators on Base" spacingTop={4} rightContent={<Link href="/ocs/products">
            <TextBody as="span" color="primary" noWrap>
              {formatMessage(messages.ocsBuyNowSeeAllLink)}
            </TextBody>
          </Link>} />
      <DynamicColumns>
        {products?.map(product => <ProductCard product={product} key={`ocs-play-tab-cards-${product.dbId}`} />)}
      </DynamicColumns>
      <ThemeProvider spectrum={spectrum} palette={paletteOverride}>
        <Box width={200}>
          <Link className={"lf5flum"} href={RouterPathname.OCS_PRODUCTS}>
            <Pressable className={buttonClass} background="foreground" testID="ocs-hero-banner-button" block>
              <TextHeadline as="span" color="primaryForeground">
                {formatMessage(messages.ocsShopAll)}
              </TextHeadline>
              <Icon size="m" name="forwardArrow" color="primaryForeground" />
            </Pressable>
          </Link>
        </Box>
      </ThemeProvider>
    </>;
}

require("./BuyNowSection.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./BuyNowSection.tsx");