/* istanbul ignore file */

import { defineMessages, useIntl } from 'react-intl';
import { getSelectedProducts, getStores, ProductCart, SlicerBasics } from '@slicekit/core';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { Button } from 'wallet-cds-web/components/Button';
import { useToggler } from '@cbhq/cds-common';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextTitle1 } from '@cbhq/cds-web/typography';
import { InfiniteScrollingCarousel } from ':dapp/components/InfiniteScrollingCarousel/InfiniteScrollingCarousel';
import { withFeatureEnabled } from ':dapp/components/withFeatureEnabled/withFeatureEnabled';
import { THE_GRAPH_KEY_INTERNAL } from ':dapp/config/env';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { OCSOutage } from '../components/OCSOutage';
import { OCSPageWrapper } from '../components/OCSPageWrapper';
import { OCSSubscribeModal } from '../components/OCSSubscribeModal';
import { RoundedImagePlaceholder } from '../progress/index.page';
import { CryptoFriendlyMerchantsSection } from './[shopId]/components/CryptoFriendlyMerchantsSection';
import { WelcomeBackBanner } from './[shopId]/components/WelcomeBackBanner';
import { BuyNowSection } from './components/BuyNowSection';
import { RedeemPrizesSection } from './components/RedeemPrizesSection';
import { ShopsYouWillLoveSection } from './components/ShopsYouWillLoveSection';
import { allProducts, allRewards, getRandomProducts, partnersShops } from './allowedProducts';
export const defaultProductImage = 'https://gvlinweehfwzdcdxkkan.supabase.co/storage/v1/object/public/slicer-images/product_default.png';
const messages = defineMessages({
  ocsRedeemPrizesSectionHeaderLink: {
    defaultMessage: 'How does it work?',
    description: 'link for the redeem prizes section header for onchain summer'
  },
  miniCoinbaseBadge: {
    defaultMessage: 'Coinbase',
    description: 'very based very based'
  },
  unlockTiming: {
    defaultMessage: 'Unlocks at level',
    description: 'unlocks after you reach a certain level'
  },
  ocsComingSoonTitle: {
    defaultMessage: 'Summer Shop coming soon!',
    description: 'Summer shop will open soon!'
  },
  ocsComingSoonDescript: {
    defaultMessage: 'Summer Shop will open up in July. Buy merch onchain and redeem prizes. In the meantime, visit the Play tab to start earning points!',
    description: 'Summer shop will open soon!'
  },
  ocsComingSoonButton: {
    defaultMessage: 'Subscribe for updates',
    description: 'Summer shop will open soon!'
  }
});
export const imageClass = "ixda46g";
export const getStaticProps = (async ({
  params,
  ...rest
}) => {
  const shopRewardProducts = allRewards.map(({
    slicerId,
    productId
  }) => ({
    slicerId,
    productIds: [productId]
  }));
  let buyNowProducts: ProductCart[] = [];
  let rewardProducts: ProductCart[] = [];
  let shops: SlicerBasics[] = [];
  try {
    [buyNowProducts, rewardProducts, shops] = await Promise.all([getSelectedProducts(getConfig(), {
      products: getRandomProducts(allProducts, 12),
      dynamicPricing: true,
      thegraphApiKey: THE_GRAPH_KEY_INTERNAL
    }), getSelectedProducts(getConfig(), {
      products: shopRewardProducts,
      dynamicPricing: true,
      thegraphApiKey: THE_GRAPH_KEY_INTERNAL
    }), getStores({
      slicerIds: partnersShops.sort(() => 0.5 - Math.random())
    })]);
  } catch (e) {
    cbReportError({
      error: coerceError(e, 'fetch slice products on server failed'),
      context: 'slice_query',
      severity: 'error',
      isHandled: true
    });
  }
  return {
    props: {
      ...params,
      ...rest,
      buyNowProducts,
      rewardProducts,
      shops
    },
    revalidate: 300
  };
}) as GetStaticProps<{
  buyNowProducts?: ProductCart[];
  rewardProducts?: ProductCart[];
  shops?: SlicerBasics[];
}>;
function OCSShopPage({
  buyNowProducts,
  rewardProducts,
  shops
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const shopIsEnabled = useIsFeatureEnabled('ocs_shop_tab');
  const {
    formatMessage
  } = useIntl();
  const [subscribeModalVisible, {
    toggle
  }] = useToggler();
  const isSmall = useIsMobile();
  const {
    gamificationApiIssue,
    exploreApiIssue
  } = usePageOutageContext();
  if (gamificationApiIssue && exploreApiIssue) {
    return <OCSOutage />;
  }

  // some shops have only rewards and some have only products -- if neither, return null
  if (shopIsEnabled && shops?.length && (buyNowProducts?.length || rewardProducts?.length)) {
    return <VStack width="100%" testID="ocs-tab-shop">
        <OCSPageWrapper>
          <Box height="8px" />
          <VStack gap={4}>
            <WelcomeBackBanner />
            <BuyNowSection products={buyNowProducts} />
            <RedeemPrizesSection products={rewardProducts} />
            <ShopsYouWillLoveSection shops={shops} />
            <CryptoFriendlyMerchantsSection />
          </VStack>
        </OCSPageWrapper>
      </VStack>;
  }
  return <VStack width="100%" testID="ocs-tab-shop-coming-soon">
      <OCSPageWrapper>
        <VStack gap={4} alignItems="center">
          <VStack width={640} alignItems="center" spacing={4}>
            <TextTitle1 as="h2" spacingBottom={1} align="center">
              {formatMessage(messages.ocsComingSoonTitle)}
            </TextTitle1>
            <Box maxWidth={isSmall ? '400px' : '100%'}>
              <TextBody as="p" color="foregroundMuted" spacingHorizontal={0} align="center" noWrap={false}>
                {formatMessage(messages.ocsComingSoonDescript)}
              </TextBody>
            </Box>
            <Box height="32px" />
            <ThemeProvider spectrum="light">
              <Button variant="secondary" onClick={toggle} testID="shop-subscribe-btn">
                {formatMessage(messages.ocsComingSoonButton)}
              </Button>
            </ThemeProvider>
          </VStack>
          <InfiniteScrollingCarousel>
            <RoundedImagePlaceholder src="https://go.wallet.coinbase.com/static/ocs/ocs-shop-preview-coinbase-back.png" />
            <RoundedImagePlaceholder src="https://go.wallet.coinbase.com/static/ocs/ocs-shop-preview-graphic-tee-revised.png" />
            <RoundedImagePlaceholder src="https://go.wallet.coinbase.com/static/ocs/ocs-shop-preview-hat-black.png" />
            <RoundedImagePlaceholder src="https://go.wallet.coinbase.com/static/ocs/ocs-shop-preview-mx1000-longsleeve-back.jpg" />
            <RoundedImagePlaceholder src="https://go.wallet.coinbase.com/static/ocs/ocs-shop-preview-playing-cards.png" />
            <RoundedImagePlaceholder src="https://go.wallet.coinbase.com/static/ocs/ocs-shop-preview-slides.png" />
            <RoundedImagePlaceholder src="https://go.wallet.coinbase.com/static/ocs/ocs-shop-preview-sticker.png" />
            <RoundedImagePlaceholder src="https://go.wallet.coinbase.com/static/ocs/ocs-shop-preview-zipup-hoodie.png" />
          </InfiniteScrollingCarousel>
        </VStack>
      </OCSPageWrapper>
      {subscribeModalVisible && <OCSSubscribeModal handleClose={toggle} />}
    </VStack>;
}
export default withFeatureEnabled(OCSShopPage, 'ocs_pages');

require("./index.page.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.page.tsx");