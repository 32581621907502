/* istanbul ignore file */

import { useMemo } from 'react';
import { useGetUserState } from 'cb-wallet-data/hooks/Gamification/useUserOCSProfile';

import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { ExternalReward } from ':dapp/pages/ocs/ext_rewards/[level]/index.page';
import { SingleProductGenericCard } from ':dapp/pages/ocs/products/components/SingleProductGenericCard';

type ExternalPrizeCardProps = {
  externalReward: ExternalReward;
};

export function ExternalPrizeCard({ externalReward }: ExternalPrizeCardProps) {
  const image = useMemo(() => externalReward.image, [externalReward]);

  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  const { userState } = useGetUserState(account?.primaryAddress);
  const userLevel = Number(userState?.levelData?.currentLevel?.level ?? 0);

  return (
    <SingleProductGenericCard
      image={image}
      name={externalReward.title}
      companyLogo={externalReward.companyLogo}
      companyName={externalReward.companyName}
      link={`/ocs/ext_rewards/${externalReward.level}`}
      userLevel={userLevel}
      unlock={externalReward.level}
      redeemed={false}
    />
  );
}
