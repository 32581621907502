/* istanbul ignore file */

type WeirdWalletSVGProps = {
  size: 'big' | 'smol';
};

export function OrderHistorydWalletSVG({ size }: WeirdWalletSVGProps) {
  return (
    <svg
      width={size === 'big' ? 374 : 240}
      height={size === 'big' ? 188 : 120}
      viewBox="0 0 374 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9638_23774)">
        <path
          d="M39.053 175.849L0 44.4221L54.1979 91.1643L44.2362 12.0801L93.6556 48.4317L176.54 176.239L39.053 175.849Z"
          fill="url(#paint0_linear_9638_23774)"
        />
        <path
          d="M334.946 175.849L373.999 44.4221L319.801 91.1643L329.763 12.0801L280.343 48.4317L197.459 176.239L334.946 175.849Z"
          fill="url(#paint1_linear_9638_23774)"
        />
        <path
          d="M120.927 34.0469H188.9V95.5169H96.054L91.8358 89.3231C83.9754 77.8092 83.6174 62.7381 90.9175 50.8653C97.3303 40.4123 108.693 34.0469 120.943 34.0469H120.927Z"
          fill="url(#paint2_linear_9638_23774)"
        />
        <path
          d="M253.074 34.0469H185.101V95.5169H277.947L282.165 89.3231C290.026 77.8092 290.384 62.7381 283.084 50.8653C276.671 40.4123 265.308 34.0469 253.059 34.0469H253.074Z"
          fill="url(#paint3_linear_9638_23774)"
        />
        <path d="M188.9 21.2695H140.679V59.3997H188.9V21.2695Z" fill="#464B55" />
        <path d="M188.9 34.0469H140.679V59.3838H188.9V34.0469Z" fill="#0052FF" />
        <path
          d="M107.073 175.851L107.058 175.835C87.539 175.835 71.7092 159.968 71.7092 140.404C71.7092 133.305 73.8417 126.362 77.8263 120.481L110.856 71.8039C116.039 64.1748 124.646 59.6035 133.861 59.6035H240.139C249.354 59.6035 257.961 64.1748 263.144 71.8039L296.174 120.481C300.158 126.362 302.291 133.305 302.291 140.404C302.291 159.968 286.461 175.835 266.942 175.835L266.927 175.851H107.073Z"
          fill="#7FD057"
        />
        <path
          d="M111.182 128.313C111.182 115.379 103.104 104.38 91.7568 99.9961L77.8571 120.497C73.8724 126.378 71.74 133.321 71.74 140.42C71.74 146.957 73.5144 153.073 76.5963 158.33C78.0128 158.533 79.4448 158.673 80.9079 158.673C97.6404 158.673 111.198 145.085 111.198 128.313H111.182Z"
          fill="url(#paint4_linear_9638_23774)"
        />
        <path
          d="M140.881 121.12C155.736 121.12 167.778 109.05 167.778 94.1606C167.778 79.2713 155.736 67.2012 140.881 67.2012C126.026 67.2012 113.984 79.2713 113.984 94.1606C113.984 109.05 126.026 121.12 140.881 121.12Z"
          fill="url(#paint5_linear_9638_23774)"
        />
        <path d="M189.288 175.85H39.053V187.832H189.288V175.85Z" fill="#0052FF" />
        <path
          d="M178.329 21.2076C183.942 21.2076 188.493 16.6464 188.493 11.0198C188.493 5.39327 183.942 0.832031 178.329 0.832031C172.715 0.832031 168.165 5.39327 168.165 11.0198C168.165 16.6464 172.715 21.2076 178.329 21.2076Z"
          fill="#FFE835"
        />
        <path
          d="M185.116 59.3848L233.337 59.3848V21.2546L185.116 21.2546V59.3848Z"
          fill="#464B55"
        />
        <path d="M233.321 34.0469H179.574V59.3838H233.321V34.0469Z" fill="#0052FF" />
        <path
          d="M262.818 128.313C262.818 115.379 270.896 104.38 282.243 99.9961L296.143 120.497C300.128 126.378 302.26 133.321 302.26 140.42C302.26 146.957 300.486 153.073 297.404 158.33C295.987 158.533 294.555 158.673 293.092 158.673C276.36 158.673 262.802 145.085 262.802 128.313H262.818Z"
          fill="url(#paint6_linear_9638_23774)"
        />
        <path
          d="M233.12 121.12C247.974 121.12 260.016 109.05 260.016 94.1606C260.016 79.2713 247.974 67.2012 233.12 67.2012C218.265 67.2012 206.223 79.2713 206.223 94.1606C206.223 109.05 218.265 121.12 233.12 121.12Z"
          fill="url(#paint7_linear_9638_23774)"
        />
        <path d="M184.711 187.832L334.946 187.832V175.85L184.711 175.85V187.832Z" fill="#0052FF" />
        <path
          d="M195.669 21.2076C201.283 21.2076 205.833 16.6464 205.833 11.0198C205.833 5.39327 201.283 0.832031 195.669 0.832031C190.056 0.832031 185.505 5.39327 185.505 11.0198C185.505 16.6464 190.056 21.2076 195.669 21.2076Z"
          fill="#FFE835"
        />
        <path
          d="M184.759 170.874C199.613 170.874 211.655 158.804 211.655 143.915C211.655 129.025 199.613 116.955 184.759 116.955C169.904 116.955 157.862 129.025 157.862 143.915C157.862 158.804 169.904 170.874 184.759 170.874Z"
          fill="url(#paint8_linear_9638_23774)"
        />
        <path
          d="M130.53 165.584C130.53 155.146 123.588 146.69 115.042 146.69C123.603 146.69 130.53 138.234 130.53 127.797C130.53 138.234 137.472 146.69 146.017 146.69C137.472 146.69 130.53 155.146 130.53 165.584Z"
          fill="black"
        />
        <path
          d="M185.88 104.519C185.88 94.0819 178.938 85.6259 170.393 85.6259C178.954 85.6259 185.88 77.1699 185.88 66.7324C185.88 77.1699 192.822 85.6259 201.368 85.6259C192.822 85.6259 185.88 94.0819 185.88 104.519Z"
          fill="black"
        />
        <path
          d="M238.957 165.912C238.957 155.474 232.015 147.018 223.47 147.018C232.031 147.018 238.957 138.562 238.957 128.125C238.957 138.562 245.899 147.018 254.444 147.018C245.899 147.018 238.957 155.474 238.957 165.912Z"
          fill="black"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9638_23774"
          x1="1.15091e-06"
          y1="94.1597"
          x2="176.54"
          y2="94.1598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D32130" />
          <stop offset={1} stopColor="#FF9533" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9638_23774"
          x1="197.459"
          y1="94.1597"
          x2="373.999"
          y2="94.1598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D32130" />
          <stop offset={1} stopColor="#FF9533" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9638_23774"
          x1="85.6814"
          y1="64.7819"
          x2="188.9"
          y2="64.7819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0052FF" />
          <stop offset={1} stopColor="#45E1E5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9638_23774"
          x1="185.101"
          y1="64.7819"
          x2="288.32"
          y2="64.7819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0052FF" />
          <stop offset={1} stopColor="#45E1E5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9638_23774"
          x1="71.74"
          y1="129.335"
          x2="111.198"
          y2="129.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE835" />
          <stop offset={1} stopColor="#FF9533" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9638_23774"
          x1="113.984"
          y1="94.1606"
          x2="167.778"
          y2="94.1606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE835" />
          <stop offset={1} stopColor="#FF9533" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_9638_23774"
          x1="262.802"
          y1="129.335"
          x2="302.26"
          y2="129.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE835" />
          <stop offset={1} stopColor="#FF9533" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_9638_23774"
          x1="206.223"
          y1="94.1606"
          x2="260.016"
          y2="94.1606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE835" />
          <stop offset={1} stopColor="#FF9533" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_9638_23774"
          x1="157.862"
          y1="143.915"
          x2="211.655"
          y2="143.915"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE835" />
          <stop offset={1} stopColor="#FF9533" />
        </linearGradient>
        <clipPath id="clip0_9638_23774">
          <rect width={374} height={187} fill="white" transform="translate(0 0.832031)" />
        </clipPath>
      </defs>
    </svg>
  );
}
