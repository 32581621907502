/* istanbul ignore file */

import { defineMessages, useIntl } from 'react-intl';
import Image from 'next/image';
import Link from 'next/link';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { TextBody, TextCaption, TextHeadline } from '@cbhq/cds-web/typography';
const messages = defineMessages({
  ocsReadMore: {
    defaultMessage: 'Read more +',
    description: 'Read more button for the description box'
  },
  ocsUnlocks: {
    defaultMessage: 'Unlocks at Level',
    description: 'Unlock label for rewards'
  },
  ocsContact: {
    defaultMessage: 'Contact',
    description: 'Button to contact a merchant'
  },
  ocsUnlocked: {
    defaultMessage: 'UNLOCKED',
    description: 'Unlocked label for rewards'
  },
  ocsRedeemed: {
    defaultMessage: 'REDEEMED',
    description: 'Redeemed label for rewards'
  }
});
const coverFit = {
  objectFit: 'cover' as const
};
type ProductData = {
  image: string;
  name: string;
  price?: string;
  companyLogo: string;
  companyName: string;
  link?: string;
  unlock?: number | string;
  userLevel?: number;
  redeemed?: boolean;
};
export function SingleProductGenericCard(props: ProductData) {
  const {
    formatMessage
  } = useIntl();
  const isProductUnlockedForUser = props.unlock ? Number(props.unlock) <= (Number(props.userLevel) || 0) : false;
  return <Link href={props.link || ''}>
      <div className={"ddt86fw"} key={`ocs-merchant-single-product-name-${props.name}-image-${props.image}-price-${props?.price}`}>
        <div className={"djwwvgg"}>
          <Image src={props.image} fill style={coverFit} alt="" />
          <div className={"d15fnwvy"}>
            <Image className={"ifqe2f2"} src={props.companyLogo} alt="" width={16} height={16} quality={100} />
            <TextCaption as="p">{props.companyName}</TextCaption>
          </div>
        </div>
        <Box height="16px" />
        <HStack justifyContent="space-between">
          <TextHeadline as="p">{props.name}</TextHeadline>
          <HStack minWidth={80} justifyContent="flex-end">
            {props?.price ? <TextBody as="p" color="foregroundMuted" align="end">
                {props.price}
              </TextBody> : null}
          </HStack>
        </HStack>
        <Box height="8px" />
        {props.unlock ? <HStack alignItems="center" gap={1}>
            {props.redeemed ? <>
                <Icon name="checkmark" size="s" color="positive" />
                <TextCaption as="p" color="positive">
                  {formatMessage(messages.ocsRedeemed)}
                </TextCaption>
              </> : <>
                {isProductUnlockedForUser ? <>
                    <Icon name="sparkle" size="s" color="positive" />
                    <TextCaption as="p" color="positive">
                      {formatMessage(messages.ocsUnlocked)}
                    </TextCaption>
                  </> : <>
                    <Icon name="lock" size="s" color="foregroundMuted" />
                    <TextCaption as="p" color="foregroundMuted">
                      {formatMessage(messages.ocsUnlocks)}&nbsp;
                      {`${props.unlock}`}
                    </TextCaption>
                  </>}
              </>}
          </HStack> : null}
      </div>
    </Link>;
}

require("./SingleProductGenericCard.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SingleProductGenericCard.tsx");